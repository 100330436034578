.logo-container{
    display: flex;
    align-items: center;
    height: 50%;
    flex-direction: column;
    animation: fadein-content 1s;
}

.picture-container{
    height: 75%;
}

.name-logo-container{
    height: 25%;
}

.picture{
    padding-top: 40px;
    height: 100%;
    animation: logo-bounce 1s infinite;
}


.name-logo{
    color: #38d88e;
    font-size: 60px;
    text-shadow: 3px 3px 0 #00be91, 6px 6px 0 #009a98;
    margin-top: 0px;
    animation: title-bounce 1s infinite;
}


/* Fade out */
.picture-fade{
    animation: fadeout-content 1s;
    opacity: 0;
}

.name-logo-fade{
    animation: fadeout-content 3s;
    opacity: 0;
}

@keyframes fadein-content {
    from {opacity: 0;}
    75% { opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeout-content {
    from {opacity: 1;}
    75% { opacity: 1;}
    to {opacity: 0;}
}

@keyframes title-bounce {
    from {padding: 0px;}
    50% {padding: 5px;}
    to {padding: 0px;}
}

@keyframes logo-bounce {
    from {margin-top: -5px;}
    50% {margin-top: 0px;}
    to {margin-top: -5px;}
}

