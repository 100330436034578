.image-wrapper{
    text-align: center;
}

.gif{
    height: 300px;
}

.job-header{
    display: flex;
    justify-content: space-between;
}

.job-title{
    display: flex;
    font-weight: bold;
    font-size: 15px;
    color: #38d88e
}

.job-date{
    display: flex;
    color: #00be91
}

.button{
    color: #f2ff66;
    font-size: 24px;
    font-weight: bold;
}

.job-text-left {
    padding-left: 10px;
    margin-top: 8px;
}

.job-text-right {
    padding-right: 10px;
    margin-top: 8px;
}

.button:hover{
    cursor: pointer;
    animation: flicker 1s infinite linear;
}

@keyframes flicker {
    from 
        {
            color: #f2ff66b2;

        }
    49% 
        {
            color: #f2ff66b2;
        }
    50% 
        {
            color: #f2ff66;
        }
    to
        { 
            color: #f2ff66;
        }
}