.image-wrapper{
    text-align: center;
}

.list-container{
    display: flex;
    justify-content: space-between;
}

.list-container{
    max-height: 300px;
}



.gif{
    height: 450px;
}

ul {
    list-style: inside;
    list-style-type: square;
}