
/* styling */
.map-container{
    position: absolute;
    top: 40px;
    left: 40px;
    width: 150px;
    height: 150px;
}

.map{
    height: 100%;
}

.player-location{
    position: absolute;
    width: 60px;
    height: 60px;
    animation: idle 1.5s infinite;
}

.player{
    height: 100%;
}

.name-logo-fade{
    opacity: 0;
}

/* locations */
.player-location.menu {
    top: 45px;
    left: 45px;
}

.player-location.about{
    top: 27px;
    left: 107px;
}

.player-location.experience{
    top: 105px;
    left: -15px;
}

.player-location.work{
    top: -15px;
    left: 45px;
}

.player-location.contact{
    top: 15px;
    left: 65px;
}

.player-location.resume{
    top: 80px;
    left: 80px;
}

/* in between */
.player-location.menu.to-about{
    animation: menu-to-about 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.about.to-menu{
    animation: about-to-menu 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.menu.to-experience{
    animation: menu-to-experience 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.experience.to-menu{
    animation: experience-to-menu 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.menu.to-work{
    animation: menu-to-work 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.work.to-menu{
    animation: work-to-menu 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.menu.to-contact{
    animation: menu-to-contact 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.contact.to-menu{
    animation: contact-to-menu 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.menu.to-resume{
    animation: menu-to-resume 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}

.player-location.resume.to-menu{
    animation: resume-to-menu 6s linear, idle 1.5s infinite;
    animation-fill-mode: forwards;
}
/* animations */
@keyframes idle {
    from 
        {
            width: 60px;
            height: 60px;
        }
    50% 
        { 
            width: 70px;
            height: 70px;
            transform: translate3d(-5px, -5px, 0);
        }
    to  
        {
            width: 60px;
            height: 60px;
        }
}

@keyframes about-to-menu {
    from  
        {
            top: 27px;
            left: 107px;
        }
    10% 
        { 
            top: 27px;
            left: 93px;
        }
    25% 
        { 
            top: 45px;
            left: 93px;
        }
    50% 
        { 
            top: 45px;
            left: 45px;
        }
    to 
        {
            top: 45px;
            left: 45px;

        }
}

@keyframes menu-to-about {
    from 
        {
            top: 45px;
            left: 45px;

        }
    50% 
        { 
            top: 45px;
            left: 45px;
        }
    75% 
        { 
            top: 45px;
            left: 93px;
        }
    90% 
        { 
            top: 27px;
            left: 93px;
        }
    to  
        {
            top: 27px;
            left: 107px;
        }
}

@keyframes menu-to-experience {
    from 
        {
            top: 45px;
            left: 45px;

        }
    50% 
        { 
            top: 105px;
            left: 45px;
        }
    to  
        {
            top: 105px;
            left: -15px;
        }
}

@keyframes experience-to-menu {
    from 
        {
            top: 105px;
            left: -15px;
        }
    50% 
        { 
            top: 105px;
            left: 45px;
        }
    to  
        {
            top: 45px;
            left: 45px;
        }
}

@keyframes menu-to-work {
    from 
        {
            top: 45px;
            left: 45px;

        }
    
    to  
        {
            top: -15px;
            left: 45px;
        }
}

@keyframes work-to-menu {
    from 
        {
            top: -15px;
            left: 45px;
        }
    
    to  
        {
            top: 45px;
            left: 45px;
        }
}

@keyframes menu-to-contact {
    from 
        {
            top: 45px;
            left: 45px;

        }
    50% 
        {
            top: 15px;
            left: 45px;

        }
    
    to  
        {
            top: 15px;
            left: 65px;
        }
}

@keyframes contact-to-menu {
    from 
        {
            top: 15px;
            left: 65px;
        }
    50%     
        {
            top: 15px;
            left: 45px;

        }
    
    to  
        {
            top: 45px;
            left: 45px;
        }
}

@keyframes menu-to-resume {
    from 
        {
            top: 45px;
            left: 45px;

        }
    50% 
        {
            top: 80px;
            left: 45px;
        }
    
    to  
        {
            top: 80px;
            left: 80px;
        }
}

@keyframes resume-to-menu {
    from 
        {
            top: 80px;
            left: 80px;
        }
    50%     
        {
            top: 80px;
            left: 45px;
        }
    to  
        {
            top: 45px;
            left: 45px;
        }
}