.container{
  position: relative;
  height: 100%;
}

.content{
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  z-index: 1;
}