
.rain {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
  }
  
  .rain.back {
    z-index: -1;
    display: block;
    opacity: .5;
    bottom: 60px;
  }
  
  
  .drop {
    position: absolute;
    bottom: 90%;
    width: 5px;
    height: 30px;
    pointer-events: none;
    animation: drop linear infinite;
    
  }
  
  @keyframes drop {
    from {
      top: 0px
    }
    to {
      top:100%
    }
  }
  
  @keyframes drop-back {
    from {
      top: 0px;
      left: 50px;
    }
    to {
      top:100%;
      left: 50px;
    }
  }
  
  .stem {
    width: 5px;
    height: 100%;
    margin-left: 1px;
    background: #085562;
    animation: stem linear infinite;
  }
  
