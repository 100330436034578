.card {
    background-color: #009a98;
    color:#9af089;
}

.hello {
    color: #38d88e;
    position: absolute;
    top: 50%;
    left: 50%;
    animation: fadein-out 3s;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
}

.menu-Item {
    color: #f2ff66;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

.hide {
    opacity: 0;
    cursor: default;
}

.enter{
    animation: fade-in 2s,  blinking 3s 2s infinite;
    opacity: 0;
}

.enter-activate{
    animation: fade-out 3s;
    opacity: 0;
}

.enter:hover{
    cursor: pointer;
}

.enter-acivate:hover{
    cursor: pointer;
}

.copyright{
    animation: fade-in 4s;
    opacity: 1;
    color: #9af089;
}


.copyright-activate{
    animation: fade-out 3s;
    opacity: 0;
    color: #9af089;
}


@keyframes blinking {
    from    { opacity: 0;}
    25%     { opacity: 1;}
    50%     { opacity: 0;}
    75%     { opacity: 1;}
    to      { opacity: 0;}
}

@keyframes fade-in {
    from {opacity: 0;}
    75% { opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadein-out {
    from {opacity: 0;}
    50% { opacity: 1;}
    to {opacity: 0;}
}

@keyframes fade-out {
    from {opacity: 1;}
    75% { opacity: 1;}
    to {opacity: 0;}
}