
/* Fade in */
.title{
    color: #00be91;
    animation: fadein-content 4s;
}

.name{
    color: #38d88e;
    animation: fadein-content 4.1s;
}

.job{
    color: #9af089;
    animation: fadein-content 4.2s;
}

/* Fade out */
.title-fade{
    color: #00be91;
    animation: fadeout-content 1s;
    opacity: 0;
}

.name-fade{
    color: #38d88e;
    animation: fadeout-content 1s;
    opacity: 0;
}

.job-fade{
    color: #9af089;
    animation: fadeout-content 1s;
    opacity: 0;
}


@keyframes fadein-content {
    from {opacity: 0;}
    75% { opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeout-content {
    from {opacity: 1;}
    75% { opacity: 1;}
    to {opacity: 0;}
}