.options-wrapper{
    position: absolute;
    text-align: center;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fade-in 1s ;

}

.options-wrapper-fade{
    position: absolute;
    text-align: center;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fade-out 1s ;
    opacity: 0;
}

.option{
    color: #f2ff66;
}

.option-hover{
    color: #f2ff66;
    animation: blinking 3s infinite;
    color: #f2ff66;
    cursor: pointer;
}

.option:hover{
    animation: blinking 3s infinite;
    color: #f2ff66;
    cursor: pointer;
}


.enter-activate{
    animation: fade-out 3s;
    opacity: 0;
}


@keyframes blinking {
    from    { opacity: 1;}
    25%     { opacity: 0;}
    50%     { opacity: 1;}
    75%     { opacity: 0;}
    to      { opacity: 1;}
}

@keyframes fade-in {
    from {opacity: 0;}
    75% { opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadein-out {
    from {opacity: 0;}
    50% { opacity: 1;}
    to {opacity: 0;}
}

@keyframes fade-out {
    from {opacity: 1;}
    75% { opacity: 1;}
    to {opacity: 0;}
}