
/* Fade in */

.child{
    color: #9af089;
    animation: fadein-content 4s;
}

/* Fade out */

.child-fade{
    color: #9af089;
    animation: fadeout-content 1s;
    opacity: 0;
}


@keyframes fadein-content {
    from {opacity: 0;}
    75% { opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeout-content {
    from {opacity: 1;}
    75% { opacity: 1;}
    to {opacity: 0;}
}